(function () {
    'use strict';

    var app = {
        isLoading: true,
        spinner: document.querySelector('.loader'),
        cardTemplate: document.querySelector('.cardTemplate'),
        container: document.querySelector('.main'),
    }

    document.getElementById('butRefresh').addEventListener('click', function () {
        app.getQuote();
    });

    app.updateQuoteCard = function (data) {
        var text = data.text;
        var card = app.currentCard;
        if (!card) {
            card = app.cardTemplate.cloneNode(true);
            card.classList.remove('cardTemplate');
            card.removeAttribute('hidden')
            app.currentCard = card;
            app.container.appendChild(card);
        }

        card.querySelector('.quote').textContent = text;

        if (app.isLoading) {
            app.spinner.setAttribute('hidden', true);
            app.container.removeAttribute('hidden');
            app.isLoading = false;
        }
    };

    app.quoteIndex = 0;

    app.quotes = [{
            text: "Don't give up!"
        },
        {
            text: "Hang in there!"
        },
        {
            text: "You've got this!"
        },
        {
            text: "Never give up!"
        },
        {
            text: "HOPE"
        }
    ]

    app.getQuote = function () {
        app.quoteIndex++;
        if (app.quoteIndex >= app.quotes.length) {
            app.quoteIndex = 0;
        }
        app.updateQuoteCard(app.quotes[app.quoteIndex]);
    };

    var firstQuote = app.quotes[app.quoteIndex];

    app.updateQuoteCard(firstQuote);

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/sw.js')
                .then(registration => {
                    console.log('SW registered: ', registration);
                })
                .catch(registrationError => {
                    console.log('SW registration failed: ', registrationError);
                })
        })
    }

})();